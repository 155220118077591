import React, { useState } from "react";
import { Create, SimpleForm, TextInput, useTranslate, useNotify, required, useInput, Edit } from "react-admin";
import { } from "ra-core";
import { AddressPickerMap } from "./AddressPickerMap";
import { MapboxGeocoderResult } from "../../core/models";
import { Organization, LatLng } from "../../core/apiClient";
import { OrganizationForm } from "./CreateOrganization";

export default function EditOrganization(props: any) {
    return (
        <Edit {...props}>
            <OrganizationForm {...props} />
        </Edit>
    );
}