import {
    DataProvider, GetListParams, GetListResult,
    UpdateResult, CreateResult,
    UpdateParams, GetOneParams, GetOneResult, DeleteParams, DeleteResult
} from "ra-core"
import { auth } from "firebase";
import { Failure, BadRequestFailure } from "../../models";
import { container } from "tsyringe";
import { PaymentsApi, PaymentCustomer, PaymentCustomerSubscription, PaymentProduct } from "../../apiClient";
import { CreateParamsWithParent, GetOneParamsWithParent, UpdateParamsWithParent, DeleteParamsWithParent } from "./common";

const placeholder = (resource: any, params: any) => {
    return Promise.reject({ resource, params });
}

const _PaymentsApi = () => container.resolve<PaymentsApi>("PaymentsApi");

export const customerDataProvider: DataProvider = {
    getList: placeholder,
    /**
     * Get Payment Customer
     */
    getOne: (resource: string, params: GetOneParams): Promise<GetOneResult> => {
        return new Promise((resolve, reject) => {
            _PaymentsApi().paymentsRetrieveCustomer(params.id, (error: Error, customer: PaymentCustomer) => {
                if (error) return reject(error);
                else return resolve({
                    data: { ...customer, id: customer.customerId }
                });
            })
        });
    },
    getMany: placeholder,
    getManyReference: placeholder,
    create: placeholder,
    update: placeholder,
    updateMany: placeholder,
    delete: placeholder,
    deleteMany: placeholder,
}

export const subscriptionDataProvider: DataProvider = {
    getList: placeholder,
    getOne: placeholder,
    getMany: placeholder,
    getManyReference: placeholder,
    create: placeholder,
    updateMany: placeholder,
    deleteMany: placeholder,
    /**
     * Update Subscription
     */
    update: (resource: string, params: UpdateParamsWithParent): Promise<UpdateResult> => {
        return new Promise((resolve, reject) => {
            _PaymentsApi()
                .paymentsChangeSubscription(params.data, params.parentId, params.id,
                    (error: Error, result: PaymentCustomerSubscription) => {
                        if (error) return reject(error);
                        else return resolve({
                            data: { ...result, id: result.subscriptionId }
                        })
                    })
        });
    },
    /**
     * Delete Subscription
     */
    delete: (resource: string, params: DeleteParamsWithParent): Promise<DeleteResult> => {
        return new Promise((resolve, reject) => {
            _PaymentsApi()
                .paymentsDeleteSubscription(params.parentId, params.id, (error: Error, deleteResult: any) => {
                    if (error) return reject(error);
                    else return resolve({});
                });
        });
    },
}

export const productDataProvider: DataProvider = {
    /**
     * Get All Available Products
     */
    getList: (resource: string, params: GetListParams): Promise<GetListResult> => {
        return new Promise((resolve, reject) => {
            _PaymentsApi().paymentsGetProducts((error: Error, products: PaymentProduct[]) => {
                if (error) return reject(error);
                return resolve({
                    data: products.map(p => ({...p, id: p.productId})),
                    total: products.length,
                });
            })
        })
    },
    getOne: placeholder,
    getMany: placeholder,
    getManyReference: placeholder,
    create: placeholder,
    update: placeholder,
    updateMany: placeholder,
    delete: placeholder,
    deleteMany: placeholder,
}
