import {
    DataProvider,
    GetListParams, GetListResult,
    GetOneParams, GetOneResult,
    UpdateParams, UpdateResult,
    CreateParams, CreateResult, Record,
    DeleteResult, GetManyResult, GetManyReferenceResult
} from "ra-core"
import { container } from "tsyringe";
import { UserProfilesApi, UserProfile, OrganizationsApi, Organization, OrganizationUserProfile } from "../../apiClient";
import { GetListParamsWithParent, CreateParamsWithParent, UpdateParamsWithParent, DeleteParamsWithParent, GetManyParamsWithParent, GetManyReferenceParamsWithParent, GetOneParamsWithParent } from "./common";

const _OrganizationsApi = () => container.resolve<OrganizationsApi>("OrganizationsApi");

const placeholder = (resource: any, params: any) => {
    return Promise.reject({ resource, params });
}

export const organizationDataProvider: DataProvider = {
    getList: placeholder,
    /**
     * Get Organization by Id
     */
    getOne: (resource: string, params: GetOneParams): Promise<GetOneResult> => {
        return new Promise((resolve, reject) => {
            _OrganizationsApi().organizationsGetOrganization(params.id, (error: Error, result: Organization, response: any) => {
                if (error) return reject(response);
                return resolve({
                    data: { ...result, id: result.id }
                });
            });
        });
    },
    getMany: placeholder,
    getManyReference: placeholder,
    /**
     * Create Organization
     */
    create: (resource: string, params: CreateParams): Promise<CreateResult> => {
        return new Promise((resolve, reject) => {
            _OrganizationsApi().organizationsCreateOrganization(params.data, (error: Error, result: Organization, resp: any) => {
                if (error) return reject(resp);
                else return resolve({
                    data: { ...result, id: result.id }
                });
            });
        })
    },
    /**
     * Update Organization
     */
    update: (resource: string, params: UpdateParams): Promise<UpdateResult> => {
        return new Promise((resolve, reject) => {
            _OrganizationsApi().organizationsUpdateOrganization(
                params.data,
                params.id,
                (error: Error, result: Organization, resp: any) => {
                    if (error) return reject(resp);
                    return resolve({
                        data: { ...result, id: result.id }
                    })
                });
        });
    },
    updateMany: placeholder,
    delete: placeholder,
    deleteMany: placeholder,
};

export const organizationUserProfileDataProvider: DataProvider = {
    /**
     * Get userprofiles belonging to an organization
     */
    getList: (resource: string, params: GetListParamsWithParent): Promise<GetListResult> => {
        return new Promise((resolve, reject) => {
            _OrganizationsApi()
                .organizationsGetOrganizationUserprofile(params.parentId, (error: Error, results: UserProfile[], resp: any) => {
                    if (error) return reject(resp);
                    else return resolve({
                        data: results, total: results.length
                    });
                });
        });
    },
    getOne: (resource: string, params: GetOneParamsWithParent): Promise<GetOneResult> => {
        console.warn("Utilizing getList to serve getOne call");
        return new Promise((resolve, reject) => {
            _OrganizationsApi()
                .organizationsGetOrganizationUserprofile(params.parentId, (error: Error, results: UserProfile[], resp: any) => {
                    if (error) return reject(resp);
                    else return resolve({
                        data: results.find(r => r.id == params.id) as Record
                    });
                });
        });
    },
    getMany: (resource: string, params: GetManyParamsWithParent): Promise<GetManyResult> => {
        console.log("getMany", resource, params);
        return Promise.reject("Not implemented");
    },
    getManyReference: (resource: string, params: GetManyReferenceParamsWithParent): Promise<GetManyReferenceResult> => {
        console.log("getMany", resource, params);
        return Promise.reject("Not implemented");
    },
    create: (resource: string, params: CreateParamsWithParent): Promise<CreateResult> => {
        return new Promise((resolve, reject) => {
            _OrganizationsApi()
                .organizationsAddOrganizationUserprofile(params.data, params.parentId, (error: Error, result: UserProfile, resp: any) => {
                    if (error) return reject(resp);
                    else return resolve({
                        data: result
                    });
                })
        });
    },
    update: (resource: string, params: UpdateParamsWithParent): Promise<UpdateResult> => {
        return new Promise((resolve, reject) => {
            _OrganizationsApi().organizationsUpdateOrganizationUserprofile(params.data, params.parentId, params.id, (error: Error, result: OrganizationUserProfile, resp: any) => {
                if (error) return reject(resp);
                else return resolve({
                    data: { ...result, id: result.userProfileId }
                });
            });
        });
    },
    updateMany: placeholder,
    delete: (resolve: string, params: DeleteParamsWithParent): Promise<DeleteResult> => {
        return new Promise((resolve, reject) => {
            _OrganizationsApi()
                .organizationsRemoveOrganizationUserprofile(params.parentId, params.id, (error: Error, result: any, resp: any) => {
                    if (error) return reject(resp);
                    return resolve({})
                });
        });
    },
    deleteMany: placeholder,
};