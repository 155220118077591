import React, { useState, useEffect } from "react";
import {
    Show, SimpleShowLayout, TextField, DateField,
    Loading, ReferenceField, useTranslate, useNotify,
    required, useInput, BooleanField, ArrayField, Datagrid
} from "react-admin";
import { } from "ra-core";
import { AddressPickerMap } from "./AddressPickerMap";
import { MapboxGeocoderResult } from "../../core/models";
import { Organization, LatLng } from "../../core/apiClient";
import _dataProviders from "../../core/services/dataProviders"
import { GetOneParamsWithParent } from "../../core/services/dataProviders/common";
import { LinearProgress, InputLabel, FormControl, Input } from "@material-ui/core";
import ChildTextField from "../Common/ChildTextField";


export default function ShowOrganization(props: any) {
    const t = useTranslate();
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="name" label={t("app.organization.messages.name")} />
                <TextField source="address" label={t("app.organization.messages.address")} />
                <ChildTextField
                    source="createdBy"
                    label={t("app.organization.messages.created_by")}
                    parent={{ id: "id", source: "createdBy" }}
                    child={{ resource: "organization/userprofiles", source: "email" }}
                />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <ChildTextField
                    source="createdBy"
                    label={t("app.organization.messages.owned_by")}
                    parent={{ id: "id", source: "ownerUserProfileId" }}
                    child={{ resource: "organization/userprofiles", source: "email" }}
                />
                <BooleanField source="hasActiveSubscription" label={t("app.organization.messages.has_active_subscription")} />
                <ArrayField source="userProfiles" label={t("app.organization.messages.users_in_this_organization")}>
                    <Datagrid>
                        <TextField source="userProfileId" label={t("app.common.messages.user_profile")} />
                        <TextField source="role" label={t("app.common.messages.role")} />
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    )
}