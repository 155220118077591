/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PaymentSessionLineItem from './PaymentSessionLineItem';

/**
* The PaymentSession model module.
* @module model/PaymentSession
* @version 0.0.1
*/
export default class PaymentSession {
    /**
    * Constructs a new <code>PaymentSession</code>.
    * @alias module:model/PaymentSession
    * @class
    * @param customerId {String} 
    * @param sessionId {String} 
    * @param successUrl {String} 
    * @param cancelUrl {String} 
    * @param mode {module:model/PaymentSession.ModeEnum} 
    * @param lineItems {Array.<module:model/PaymentSessionLineItem>} 
    */

    constructor(customerId, sessionId, successUrl, cancelUrl, mode, lineItems) {
        
        
        this['customerId'] = customerId;
        this['sessionId'] = sessionId;
        this['successUrl'] = successUrl;
        this['cancelUrl'] = cancelUrl;
        this['mode'] = mode;
        this['lineItems'] = lineItems;
        
    }

    /**
    * Constructs a <code>PaymentSession</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PaymentSession} obj Optional instance to populate.
    * @return {module:model/PaymentSession} The populated <code>PaymentSession</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PaymentSession();
                        
            
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
            }
            if (data.hasOwnProperty('sessionId')) {
                obj['sessionId'] = ApiClient.convertToType(data['sessionId'], 'String');
            }
            if (data.hasOwnProperty('successUrl')) {
                obj['successUrl'] = ApiClient.convertToType(data['successUrl'], 'String');
            }
            if (data.hasOwnProperty('cancelUrl')) {
                obj['cancelUrl'] = ApiClient.convertToType(data['cancelUrl'], 'String');
            }
            if (data.hasOwnProperty('mode')) {
                obj['mode'] = ApiClient.convertToType(data['mode'], 'String');
            }
            if (data.hasOwnProperty('customerEmail')) {
                obj['customerEmail'] = ApiClient.convertToType(data['customerEmail'], 'String');
            }
            if (data.hasOwnProperty('lineItems')) {
                obj['lineItems'] = ApiClient.convertToType(data['lineItems'], [PaymentSessionLineItem]);
            }
        }
        return obj;
    }

    /**
    * @member {String} customerId
    */
    'customerId' = undefined;
    /**
    * @member {String} sessionId
    */
    'sessionId' = undefined;
    /**
    * @member {String} successUrl
    */
    'successUrl' = undefined;
    /**
    * @member {String} cancelUrl
    */
    'cancelUrl' = undefined;
    /**
    * @member {module:model/PaymentSession.ModeEnum} mode
    */
    'mode' = undefined;
    /**
    * @member {String} customerEmail
    */
    'customerEmail' = undefined;
    /**
    * @member {Array.<module:model/PaymentSessionLineItem>} lineItems
    */
    'lineItems' = undefined;



    /**
    * Allowed values for the <code>mode</code> property.
    * @enum {String}
    * @readonly
    */
    static ModeEnum = {
        /**
         * value: "subscription"
         * @const
         */
        "subscription": "subscription",
        /**
         * value: "payment"
         * @const
         */
        "payment": "payment",
        /**
         * value: "setup"
         * @const
         */
        "setup": "setup"    };

}
