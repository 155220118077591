/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The UserProfileOrganization model module.
* @module model/UserProfileOrganization
* @version 0.0.1
*/
export default class UserProfileOrganization {
    /**
    * Constructs a new <code>UserProfileOrganization</code>.
    * This references a list of Organizations that the UserProfile is a part of.
    * @alias module:model/UserProfileOrganization
    * @class
    * @param organizationId {String} 
    * @param role {module:model/UserProfileOrganization.RoleEnum} 
    * @param allowLocationAccess {Boolean} This property specifies whether the organization can access Users live location while GPS Tracking is enabled
    */

    constructor(organizationId, role, allowLocationAccess) {
        
        
        this['organizationId'] = organizationId;
        this['role'] = role;
        this['allowLocationAccess'] = allowLocationAccess;
        
    }

    /**
    * Constructs a <code>UserProfileOrganization</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/UserProfileOrganization} obj Optional instance to populate.
    * @return {module:model/UserProfileOrganization} The populated <code>UserProfileOrganization</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserProfileOrganization();
                        
            
            if (data.hasOwnProperty('organizationId')) {
                obj['organizationId'] = ApiClient.convertToType(data['organizationId'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'String');
            }
            if (data.hasOwnProperty('allowLocationAccess')) {
                obj['allowLocationAccess'] = ApiClient.convertToType(data['allowLocationAccess'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * @member {String} organizationId
    */
    'organizationId' = undefined;
    /**
    * @member {module:model/UserProfileOrganization.RoleEnum} role
    */
    'role' = undefined;
    /**
    * This property specifies whether the organization can access Users live location while GPS Tracking is enabled
    * @member {Boolean} allowLocationAccess
    */
    'allowLocationAccess' = undefined;



    /**
    * Allowed values for the <code>role</code> property.
    * @enum {String}
    * @readonly
    */
    static RoleEnum = {
        /**
         * value: "ADMIN"
         * @const
         */
        "ADMIN": "ADMIN",
        /**
         * value: "USER"
         * @const
         */
        "USER": "USER"    };

}
