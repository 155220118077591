/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PaymentCustomerSubscriptionPrice from './PaymentCustomerSubscriptionPrice';

/**
* The PaymentCustomerSubscription model module.
* @module model/PaymentCustomerSubscription
* @version 0.0.1
*/
export default class PaymentCustomerSubscription {
    /**
    * Constructs a new <code>PaymentCustomerSubscription</code>.
    * @alias module:model/PaymentCustomerSubscription
    * @class
    * @param subscriptionId {String} 
    * @param price {module:model/PaymentCustomerSubscriptionPrice} 
    * @param status {module:model/PaymentCustomerSubscription.StatusEnum} 
    */

    constructor(subscriptionId, price, status) {
        
        
        this['subscriptionId'] = subscriptionId;
        this['price'] = price;
        this['status'] = status;
        
    }

    /**
    * Constructs a <code>PaymentCustomerSubscription</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PaymentCustomerSubscription} obj Optional instance to populate.
    * @return {module:model/PaymentCustomerSubscription} The populated <code>PaymentCustomerSubscription</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PaymentCustomerSubscription();
                        
            
            if (data.hasOwnProperty('subscriptionId')) {
                obj['subscriptionId'] = ApiClient.convertToType(data['subscriptionId'], 'String');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = PaymentCustomerSubscriptionPrice.constructFromObject(data['price']);
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} subscriptionId
    */
    'subscriptionId' = undefined;
    /**
    * @member {module:model/PaymentCustomerSubscriptionPrice} price
    */
    'price' = undefined;
    /**
    * @member {module:model/PaymentCustomerSubscription.StatusEnum} status
    */
    'status' = undefined;



    /**
    * Allowed values for the <code>status</code> property.
    * @enum {String}
    * @readonly
    */
    static StatusEnum = {
        /**
         * value: "incomplete"
         * @const
         */
        "incomplete": "incomplete",
        /**
         * value: "incomplete_expired"
         * @const
         */
        "incomplete_expired": "incomplete_expired",
        /**
         * value: "trialing"
         * @const
         */
        "trialing": "trialing",
        /**
         * value: "active"
         * @const
         */
        "active": "active",
        /**
         * value: "past_due"
         * @const
         */
        "past_due": "past_due",
        /**
         * value: "canceled"
         * @const
         */
        "canceled": "canceled",
        /**
         * value: "unpaid"
         * @const
         */
        "unpaid": "unpaid"    };

}
