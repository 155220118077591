import { INotificationService } from "./notificationService";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@aspnet/signalr"

export class NotificationServiceImpl implements INotificationService {
    joinGroup(token: string): Promise<HubConnection> {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_ENDPOINT}/api/notification_group`,
                {
                    headers: {
                        token
                    }
                })
                .then(() => { resolve() })
                .catch(err => reject(err));
        });
    }
    async connect(token: string, uid: string): Promise<HubConnection> {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_ENDPOINT}/api/notification_negotiate`, {
                headers: {
                    token,
                    "x-ms-client-principal-id": uid
                }
            })
                .then(async response => {
                    const info = await response.json();
                    const options = {
                        accessTokenFactory: () => info.accessToken
                    };
                    const connection = new HubConnectionBuilder()
                        .withUrl(info.url, options)
                        .configureLogging(LogLevel.Information)
                        .build();
                    resolve(connection);
                })
                .catch(err => reject(err));
        });
    }
    onMessage() {

    }
}