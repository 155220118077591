import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { auth } from 'firebase';
import { FirebaseAuth } from "react-firebaseui";
import { Redirect, useHistory } from "react-router";
import { useLogin } from "react-admin";
import { useLocation } from "react-router-dom";
import { Loading } from "react-admin";

import "./LoginPage.css";

export function LoginPage() {
    const login = useLogin();
    const history = useHistory();
    const [checkingAuth, setCheckingAuth] = useState(true);
    const doLogin = (authResult: any) => { login(authResult); history.push("/"); }
    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            auth.EmailAuthProvider.PROVIDER_ID,
            auth.GoogleAuthProvider.PROVIDER_ID
        ],
        credentialHelper: "none",
        callbacks: {
            signInSuccessWithAuthResult: doLogin
        }
    } as firebaseui.auth.Config

    useEffect(() => {
        auth().onAuthStateChanged(value => {
            if (value) {
                doLogin(value);
            }
            else {
                setCheckingAuth(false);
            }
        });
    }, []);

    if (checkingAuth) {
        return <Loading />;
    }

    return (
        <>
            <FirebaseAuth firebaseAuth={auth()} uiConfig={uiConfig} />
        </>
    )
}