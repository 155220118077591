import "reflect-metadata";
import { container } from "tsyringe";
import { NotificationServiceImpl } from "./services/notificationServiceImpl";
import { UserProfilesApi, OrganizationsApi, PaymentsApi, ApiClient } from "./apiClient";

let isRegistered = false;

export function register() {
    if (isRegistered) {
        return;
    }
    container.register("NotificationService", { useClass: NotificationServiceImpl });
    container.register("ApiClient", { useValue: ApiClient.instance });
    container.register("UserProfilesApi", { useValue: new UserProfilesApi() });
    container.register("OrganizationsApi", { useValue: new OrganizationsApi() });
    container.register("PaymentsApi", { useValue: new PaymentsApi() });
    isRegistered = true;
}