/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LatLng from './LatLng';
import OrganizationUserProfile from './OrganizationUserProfile';

/**
* The Organization model module.
* @module model/Organization
* @version 0.0.1
*/
export default class Organization {
    /**
    * Constructs a new <code>Organization</code>.
    * @alias module:model/Organization
    * @class
    * @param address {String} 
    * @param createdAt {Date} 
    * @param createdBy {String} 
    * @param ownerUserProfileId {String} 
    * @param updatedBy {String} 
    * @param id {String} 
    * @param location {module:model/LatLng} 
    * @param name {String} 
    * @param updatedAt {Date} 
    * @param userProfiles {Array.<module:model/OrganizationUserProfile>} 
    * @param hasActiveSubscription {Boolean} 
    */

    constructor(address, createdAt, createdBy, ownerUserProfileId, updatedBy, id, location, name, updatedAt, userProfiles, hasActiveSubscription) {
        
        
        this['address'] = address;
        this['createdAt'] = createdAt;
        this['createdBy'] = createdBy;
        this['ownerUserProfileId'] = ownerUserProfileId;
        this['updatedBy'] = updatedBy;
        this['id'] = id;
        this['location'] = location;
        this['name'] = name;
        this['updatedAt'] = updatedAt;
        this['userProfiles'] = userProfiles;
        this['hasActiveSubscription'] = hasActiveSubscription;
        
    }

    /**
    * Constructs a <code>Organization</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Organization} obj Optional instance to populate.
    * @return {module:model/Organization} The populated <code>Organization</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Organization();
                        
            
            if (data.hasOwnProperty('address')) {
                obj['address'] = ApiClient.convertToType(data['address'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('ownerUserProfileId')) {
                obj['ownerUserProfileId'] = ApiClient.convertToType(data['ownerUserProfileId'], 'String');
            }
            if (data.hasOwnProperty('updatedBy')) {
                obj['updatedBy'] = ApiClient.convertToType(data['updatedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = LatLng.constructFromObject(data['location']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'Date');
            }
            if (data.hasOwnProperty('userProfiles')) {
                obj['userProfiles'] = ApiClient.convertToType(data['userProfiles'], [OrganizationUserProfile]);
            }
            if (data.hasOwnProperty('hasActiveSubscription')) {
                obj['hasActiveSubscription'] = ApiClient.convertToType(data['hasActiveSubscription'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * @member {String} address
    */
    'address' = undefined;
    /**
    * @member {Date} createdAt
    */
    'createdAt' = undefined;
    /**
    * @member {String} createdBy
    */
    'createdBy' = undefined;
    /**
    * @member {String} ownerUserProfileId
    */
    'ownerUserProfileId' = undefined;
    /**
    * @member {String} updatedBy
    */
    'updatedBy' = undefined;
    /**
    * @member {String} id
    */
    'id' = undefined;
    /**
    * @member {module:model/LatLng} location
    */
    'location' = undefined;
    /**
    * @member {String} name
    */
    'name' = undefined;
    /**
    * @member {Date} updatedAt
    */
    'updatedAt' = undefined;
    /**
    * @member {Array.<module:model/OrganizationUserProfile>} userProfiles
    */
    'userProfiles' = undefined;
    /**
    * @member {Boolean} hasActiveSubscription
    */
    'hasActiveSubscription' = undefined;




}
