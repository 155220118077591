import React, { useEffect, useState } from "react";
import mapboxgl, { Map, Popup, GeoJSONSource, Marker } from "mapbox-gl";
import { CallMissedOutgoingSharp } from "@material-ui/icons";
import { LatLng } from "../../core/apiClient";
import { MapboxGeocoderResult } from "../../core/models";
import { Typography } from "@material-ui/core";
var MapboxGeocoder = require("@mapbox/mapbox-gl-geocoder");

type AddressPickerMapProps = {
    location?: LatLng;
    onAddressSelected: (result: MapboxGeocoderResult) => void;
}
export function AddressPickerMap(props: AddressPickerMapProps) {
    const [map, setMap] = useState<Map>(null as any);
    const [geoCoder, setGeoCoder] = useState(null as any);
    const [marker, setMarker] = useState<Marker>(null as any);
    const [selectedLocation, setSelectedLocation] = useState<MapboxGeocoderResult>();

    const isLocationValid = (location?: LatLng) => {
        return location && location.longitude && location.latitude;
    }
    useEffect(() => {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN as string;
        let center: [number, number] = [-79.3284366302966, 43.727767659638175];
        console.log("props.location", props.location);
        if (isLocationValid(props.location)) {
            center = [props.location!.longitude, props.location!.latitude];
        }
        const m = new Map({
            container: "map",
            style: "mapbox://styles/mapbox/dark-v10",
            center,
            zoom: 14,
            minZoom: 4
        });
        const gc = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl
        });

        gc.on("result", (result: any) => {
            props.onAddressSelected(result.result as MapboxGeocoderResult);
            setSelectedLocation(result.result);
        });
        m.addControl(gc);

        const mapMarker = new Marker();
        if (isLocationValid(props.location)) {
            mapMarker.setLngLat([props.location!.longitude, props.location!.latitude]);
            mapMarker.addTo(m);
        }

        setMarker(mapMarker);
        setMap(m);
        setGeoCoder(gc);
    }, []);

    useEffect(() => {
        if (props.location && marker) {
            marker.setLngLat([props.location.longitude, props.location.latitude])
        }
    }, [props.location])

    return (
        <>
            {selectedLocation &&
                <Typography>
                    {selectedLocation.place_name} ({selectedLocation.center[0]},{selectedLocation.center[1]})
                </Typography>
            }
            <div style={{ height: 400, width: 764 }} id="map" />
        </>
    );
}