/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The LatLng model module.
* @module model/LatLng
* @version 0.0.1
*/
export default class LatLng {
    /**
    * Constructs a new <code>LatLng</code>.
    * @alias module:model/LatLng
    * @class
    * @param latitude {Number} 
    * @param longitude {Number} 
    */

    constructor(latitude, longitude) {
        
        
        this['latitude'] = latitude;
        this['longitude'] = longitude;
        
    }

    /**
    * Constructs a <code>LatLng</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/LatLng} obj Optional instance to populate.
    * @return {module:model/LatLng} The populated <code>LatLng</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LatLng();
                        
            
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {Number} latitude
    */
    'latitude' = undefined;
    /**
    * @member {Number} longitude
    */
    'longitude' = undefined;




}
