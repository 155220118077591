/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import UserProfileOrganization from './UserProfileOrganization';

/**
* The UserProfile model module.
* @module model/UserProfile
* @version 0.0.1
*/
export default class UserProfile {
    /**
    * Constructs a new <code>UserProfile</code>.
    * @alias module:model/UserProfile
    * @class
    * @param firstName {String} 
    * @param lastName {String} 
    * @param email {String} 
    * @param id {String} 
    * @param uid {String} 
    * @param createdAt {Date} 
    * @param updatedAt {Date} 
    * @param createdBy {String} 
    * @param updatedBy {String} 
    * @param organizations {Array.<module:model/UserProfileOrganization>} 
    */

    constructor(firstName, lastName, email, id, uid, createdAt, updatedAt, createdBy, updatedBy, organizations) {
        
        
        this['firstName'] = firstName;
        this['lastName'] = lastName;
        this['email'] = email;
        this['id'] = id;
        this['uid'] = uid;
        this['createdAt'] = createdAt;
        this['updatedAt'] = updatedAt;
        this['createdBy'] = createdBy;
        this['updatedBy'] = updatedBy;
        this['organizations'] = organizations;
        
    }

    /**
    * Constructs a <code>UserProfile</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/UserProfile} obj Optional instance to populate.
    * @return {module:model/UserProfile} The populated <code>UserProfile</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserProfile();
                        
            
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('uid')) {
                obj['uid'] = ApiClient.convertToType(data['uid'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('updatedBy')) {
                obj['updatedBy'] = ApiClient.convertToType(data['updatedBy'], 'String');
            }
            if (data.hasOwnProperty('organizations')) {
                obj['organizations'] = ApiClient.convertToType(data['organizations'], [UserProfileOrganization]);
            }
        }
        return obj;
    }

    /**
    * @member {String} firstName
    */
    'firstName' = undefined;
    /**
    * @member {String} lastName
    */
    'lastName' = undefined;
    /**
    * @member {String} email
    */
    'email' = undefined;
    /**
    * @member {String} id
    */
    'id' = undefined;
    /**
    * @member {String} uid
    */
    'uid' = undefined;
    /**
    * @member {Date} createdAt
    */
    'createdAt' = undefined;
    /**
    * @member {Date} updatedAt
    */
    'updatedAt' = undefined;
    /**
    * @member {String} createdBy
    */
    'createdBy' = undefined;
    /**
    * @member {String} updatedBy
    */
    'updatedBy' = undefined;
    /**
    * @member {Array.<module:model/UserProfileOrganization>} organizations
    */
    'organizations' = undefined;




}
