import React from "react";
import { Resource, Show, SimpleShowLayout, TextField, DateField } from "react-admin";
import { ShowControllerProps } from "ra-core";
import ListSubscriptions from "../Subscriptions/ListSubscriptions";

export default function ShowUserProfile(props: ShowControllerProps) {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="email" />
                <DateField source="createdAt" />
            </SimpleShowLayout>
        </Show>
    );
}