import React, { useState } from "react";
import { Create, SimpleForm, TextInput, useTranslate, useNotify, required, useInput } from "react-admin";
import { } from "ra-core";
import { AddressPickerMap } from "./AddressPickerMap";
import { MapboxGeocoderResult } from "../../core/models";
import { Organization, LatLng } from "../../core/apiClient";

export const organizationTransform = (organization: Organization) => {
    const address = (organization as any).geocoder as MapboxGeocoderResult;
    organization.address = address?.place_name;
    (organization.location as LatLng) = { latitude: address?.center[0], longitude: address?.center[1] };
    return organization;
}

export function OrganizationForm(props: any) {
    const t = useTranslate();
    const validateOrgCreation = (organization: Organization) => {
        const errors: any = {};
        const address = (organization as any).geocoder as MapboxGeocoderResult;
        if (!address) {
            errors.address = ["Address is missing"];
        }
        return errors;
    };
    return (
        <SimpleForm {...props}>
            <TextInput validate={[required()]} label={t("app.organization.messages.name")} source="name" />
            {/* <TextInput hidden disabled label={t("app.organization.messages.ownerUserProfileId")} source="ownerUserProfileId" /> */}
            <AddressInput source="geocoder" />
        </SimpleForm>
    );
}

export default function CreateOrganization(props: any) {

    return (
        <Create {...props} transform={organizationTransform}>
            <OrganizationForm />
        </Create>
    );
}

function AddressInput(props: any) {
    const prop = useInput(props);
    // console.log("input is ", prop.input);
    const onAddressSelected = (result: MapboxGeocoderResult) => {
        // console.log("onAddressSelected", result);
        prop.input.onChange({ target: { value: result } })
    }
    return <AddressPickerMap onAddressSelected={onAddressSelected} />
}