import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

type LinkButtonProps = {
    to: any;
    variant?: any;
    color?: any;
    children: any;
}

export default function LinkButton(props: LinkButtonProps) {
    return (
        <Link to={props.to}>
            <Button
                variant={props.variant || "contained"}
                color={props.color || "primary"}
            >
                {props.children}
            </Button>
        </Link>
    )
}