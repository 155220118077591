/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Body from '../model/Body';
import PaymentCustomer from '../model/PaymentCustomer';
import PaymentCustomerSubscription from '../model/PaymentCustomerSubscription';
import PaymentProduct from '../model/PaymentProduct';
import PaymentSession from '../model/PaymentSession';

/**
* Payments service.
* @module api/PaymentsApi
* @version 0.0.1
*/
export default class PaymentsApi {

    /**
    * Constructs a new PaymentsApi. 
    * @alias module:api/PaymentsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the paymentsChangeSubscription operation.
     * @callback module:api/PaymentsApi~paymentsChangeSubscriptionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PaymentCustomerSubscription} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Change Customer Subscription - Can be used to downgrade or upgrade subscriptions
     * @param {module:api/PaymentsApi~paymentsChangeSubscriptionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PaymentCustomerSubscription}
     */
    paymentsChangeSubscription(body, customerId, subscriptionId, callback) {
      let postBody = body;

      let pathParams = {
        'customerId': customerId,
        'subscriptionId': subscriptionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = PaymentCustomerSubscription;

      return this.apiClient.callApi(
        '/payments/customers/{customerId}/subscriptions/{subscriptionId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the paymentsCreatePaymentSession operation.
     * @callback module:api/PaymentsApi~paymentsCreatePaymentSessionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PaymentSession} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * checkout-session
     * @param {module:api/PaymentsApi~paymentsCreatePaymentSessionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PaymentSession}
     */
    paymentsCreatePaymentSession(body, customerId, callback) {
      let postBody = body;

      let pathParams = {
        'customerId': customerId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = PaymentSession;

      return this.apiClient.callApi(
        '/payments/customers/{customerId}/session', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the paymentsDeleteSubscription operation.
     * @callback module:api/PaymentsApi~paymentsDeleteSubscriptionCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete Customer Subscription
     * @param {module:api/PaymentsApi~paymentsDeleteSubscriptionCallback} callback The callback function, accepting three arguments: error, data, response
     */
    paymentsDeleteSubscription(customerId, subscriptionId, callback) {
      let postBody = null;

      let pathParams = {
        'customerId': customerId,
        'subscriptionId': subscriptionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/payments/customers/{customerId}/subscriptions/{subscriptionId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the paymentsGetProducts operation.
     * @callback module:api/PaymentsApi~paymentsGetProductsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/PaymentProduct>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieve Customer
     * @param {module:api/PaymentsApi~paymentsGetProductsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/PaymentProduct>}
     */
    paymentsGetProducts(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [PaymentProduct];

      return this.apiClient.callApi(
        '/payments/products', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the paymentsRetrieveCustomer operation.
     * @callback module:api/PaymentsApi~paymentsRetrieveCustomerCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PaymentCustomer} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieve Customer
     * @param {module:api/PaymentsApi~paymentsRetrieveCustomerCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PaymentCustomer}
     */
    paymentsRetrieveCustomer(customerId, callback) {
      let postBody = null;

      let pathParams = {
        'customerId': customerId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PaymentCustomer;

      return this.apiClient.callApi(
        '/payments/customers/{customerId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
