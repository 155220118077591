/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import UserProfile from '../model/UserProfile';
import UserProfileOrganization from '../model/UserProfileOrganization';

/**
* UserProfiles service.
* @module api/UserProfilesApi
* @version 0.0.1
*/
export default class UserProfilesApi {

    /**
    * Constructs a new UserProfilesApi. 
    * @alias module:api/UserProfilesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the userProfilesCreateUserProfile operation.
     * @callback module:api/UserProfilesApi~userProfilesCreateUserProfileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserProfile} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create UserProfile
     * @param {module:api/UserProfilesApi~userProfilesCreateUserProfileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserProfile}
     */
    userProfilesCreateUserProfile(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UserProfile;

      return this.apiClient.callApi(
        '/userprofiles/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the userProfilesJoinOrganization operation.
     * @callback module:api/UserProfilesApi~userProfilesJoinOrganizationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserProfileOrganization} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Join Organization
     * @param {module:api/UserProfilesApi~userProfilesJoinOrganizationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserProfileOrganization}
     */
    userProfilesJoinOrganization(userProfileId, organizationId, callback) {
      let postBody = null;

      let pathParams = {
        'userProfileId': userProfileId,
        'organizationId': organizationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UserProfileOrganization;

      return this.apiClient.callApi(
        '/userprofiles/{userProfileId}/organizations/{organizationId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the userProfilesLeaveOrganization operation.
     * @callback module:api/UserProfilesApi~userProfilesLeaveOrganizationCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Leave Organization
     * @param {module:api/UserProfilesApi~userProfilesLeaveOrganizationCallback} callback The callback function, accepting three arguments: error, data, response
     */
    userProfilesLeaveOrganization(userProfileId, organizationId, callback) {
      let postBody = null;

      let pathParams = {
        'userProfileId': userProfileId,
        'organizationId': organizationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/userprofiles/{userProfileId}/organizations/{organizationId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the userProfilesRetrieveUserProfile operation.
     * @callback module:api/UserProfilesApi~userProfilesRetrieveUserProfileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserProfile} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieve UserProfile
     * @param {module:api/UserProfilesApi~userProfilesRetrieveUserProfileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserProfile}
     */
    userProfilesRetrieveUserProfile(userProfileId, callback) {
      let postBody = null;

      let pathParams = {
        'userProfileId': userProfileId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UserProfile;

      return this.apiClient.callApi(
        '/userprofiles/{userProfileId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the userProfilesRetrieveUserProfileOrganizationByOrganizationId operation.
     * @callback module:api/UserProfilesApi~userProfilesRetrieveUserProfileOrganizationByOrganizationIdCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/UserProfileOrganization>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieve UserProfileOrganization by OrganizationId
     * @param {module:api/UserProfilesApi~userProfilesRetrieveUserProfileOrganizationByOrganizationIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/UserProfileOrganization>}
     */
    userProfilesRetrieveUserProfileOrganizationByOrganizationId(userProfileId, organizationId, callback) {
      let postBody = null;

      let pathParams = {
        'userProfileId': userProfileId,
        'organizationId': organizationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [UserProfileOrganization];

      return this.apiClient.callApi(
        '/userprofiles/{userProfileId}/organizations/{organizationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the userProfilesRetrieveUserProfileOrganizations operation.
     * @callback module:api/UserProfilesApi~userProfilesRetrieveUserProfileOrganizationsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/UserProfileOrganization>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieve UserProfileOrganizations
     * @param {module:api/UserProfilesApi~userProfilesRetrieveUserProfileOrganizationsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/UserProfileOrganization>}
     */
    userProfilesRetrieveUserProfileOrganizations(userProfileId, callback) {
      let postBody = null;

      let pathParams = {
        'userProfileId': userProfileId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [UserProfileOrganization];

      return this.apiClient.callApi(
        '/userprofiles/{userProfileId}/organizations', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the userProfilesUpdateUserProfile operation.
     * @callback module:api/UserProfilesApi~userProfilesUpdateUserProfileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserProfile} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update UserProfile
     * @param {module:api/UserProfilesApi~userProfilesUpdateUserProfileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserProfile}
     */
    userProfilesUpdateUserProfile(body, userProfileId, callback) {
      let postBody = body;

      let pathParams = {
        'userProfileId': userProfileId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UserProfile;

      return this.apiClient.callApi(
        '/userprofiles/{userProfileId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the userProfilesUpdateUserProfileOrganization operation.
     * @callback module:api/UserProfilesApi~userProfilesUpdateUserProfileOrganizationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserProfileOrganization} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update UserProfileOrganization
     * @param {module:api/UserProfilesApi~userProfilesUpdateUserProfileOrganizationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserProfileOrganization}
     */
    userProfilesUpdateUserProfileOrganization(body, userProfileId, organizationId, callback) {
      let postBody = body;

      let pathParams = {
        'userProfileId': userProfileId,
        'organizationId': organizationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UserProfileOrganization;

      return this.apiClient.callApi(
        '/userprofiles/{userProfileId}/organizations/{organizationId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
