import React from "react";
import { List, Datagrid, TextField, DeleteButton } from "react-admin";
import { ListControllerProps, AdminProps } from "ra-core";
import { Card, CardContent, CardHeader, CardActions } from "@material-ui/core";
import { Empty } from "../Layout";

export default function ListProducts(props: ListControllerProps) {
    return (
        <>
            <List {...props} pagination={<Empty />} exporter={false}>
                <Datagrid>
                    <TextField source="name"></TextField>
                    <TextField source="active"></TextField>
                    <TextField source="description"></TextField>
                </Datagrid>
            </List>
        </>
    )
}