/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Organization from '../model/Organization';
import OrganizationUserProfile from '../model/OrganizationUserProfile';
import UserProfile from '../model/UserProfile';

/**
* Organizations service.
* @module api/OrganizationsApi
* @version 0.0.1
*/
export default class OrganizationsApi {

    /**
    * Constructs a new OrganizationsApi. 
    * @alias module:api/OrganizationsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the organizationsAddOrganizationUserprofile operation.
     * @callback module:api/OrganizationsApi~organizationsAddOrganizationUserprofileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrganizationUserProfile} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrganizationsApi~organizationsAddOrganizationUserprofileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrganizationUserProfile}
     */
    organizationsAddOrganizationUserprofile(body, organizationId, callback) {
      let postBody = body;

      let pathParams = {
        'organizationId': organizationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrganizationUserProfile;

      return this.apiClient.callApi(
        '/organizations/{organizationId}/userprofiles', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the organizationsCreateOrganization operation.
     * @callback module:api/OrganizationsApi~organizationsCreateOrganizationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Organization} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create Organization
     * @param {module:api/OrganizationsApi~organizationsCreateOrganizationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Organization}
     */
    organizationsCreateOrganization(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Organization;

      return this.apiClient.callApi(
        '/organizations/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the organizationsGetOrganization operation.
     * @callback module:api/OrganizationsApi~organizationsGetOrganizationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Organization} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrganizationsApi~organizationsGetOrganizationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Organization}
     */
    organizationsGetOrganization(organizationId, callback) {
      let postBody = null;

      let pathParams = {
        'organizationId': organizationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Organization;

      return this.apiClient.callApi(
        '/organizations/{organizationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the organizationsGetOrganizationUserprofile operation.
     * @callback module:api/OrganizationsApi~organizationsGetOrganizationUserprofileCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/UserProfile>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrganizationsApi~organizationsGetOrganizationUserprofileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/UserProfile>}
     */
    organizationsGetOrganizationUserprofile(organizationId, callback) {
      let postBody = null;

      let pathParams = {
        'organizationId': organizationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [UserProfile];

      return this.apiClient.callApi(
        '/organizations/{organizationId}/userprofiles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the organizationsRemoveOrganizationUserprofile operation.
     * @callback module:api/OrganizationsApi~organizationsRemoveOrganizationUserprofileCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrganizationsApi~organizationsRemoveOrganizationUserprofileCallback} callback The callback function, accepting three arguments: error, data, response
     */
    organizationsRemoveOrganizationUserprofile(organizationId, userProfileId, callback) {
      let postBody = null;

      let pathParams = {
        'organizationId': organizationId,
        'userProfileId': userProfileId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/organizations/{organizationId}/userprofiles/{userProfileId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the organizationsUpdateOrganization operation.
     * @callback module:api/OrganizationsApi~organizationsUpdateOrganizationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Organization} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrganizationsApi~organizationsUpdateOrganizationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Organization}
     */
    organizationsUpdateOrganization(body, organizationId, callback) {
      let postBody = body;

      let pathParams = {
        'organizationId': organizationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Organization;

      return this.apiClient.callApi(
        '/organizations/{organizationId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the organizationsUpdateOrganizationUserprofile operation.
     * @callback module:api/OrganizationsApi~organizationsUpdateOrganizationUserprofileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrganizationUserProfile} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrganizationsApi~organizationsUpdateOrganizationUserprofileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrganizationUserProfile}
     */
    organizationsUpdateOrganizationUserprofile(body, organizationId, userProfileId, callback) {
      let postBody = body;

      let pathParams = {
        'organizationId': organizationId,
        'userProfileId': userProfileId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['idtoken'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrganizationUserProfile;

      return this.apiClient.callApi(
        '/organizations/{organizationId}/userprofiles/{userProfileId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
