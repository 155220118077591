/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PaymentCustomerSubscription from './PaymentCustomerSubscription';

/**
* The PaymentCustomer model module.
* @module model/PaymentCustomer
* @version 0.0.1
*/
export default class PaymentCustomer {
    /**
    * Constructs a new <code>PaymentCustomer</code>.
    * @alias module:model/PaymentCustomer
    * @class
    * @param customerId {String} 
    * @param address {String} 
    * @param description {String} 
    * @param email {String} 
    * @param metadata {Object} 
    * @param name {String} 
    * @param phone {String} 
    * @param shippingAddress {String} 
    * @param subscriptions {Array.<module:model/PaymentCustomerSubscription>} 
    */

    constructor(customerId, address, description, email, metadata, name, phone, shippingAddress, subscriptions) {
        
        
        this['customerId'] = customerId;
        this['address'] = address;
        this['description'] = description;
        this['email'] = email;
        this['metadata'] = metadata;
        this['name'] = name;
        this['phone'] = phone;
        this['shippingAddress'] = shippingAddress;
        this['subscriptions'] = subscriptions;
        
    }

    /**
    * Constructs a <code>PaymentCustomer</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PaymentCustomer} obj Optional instance to populate.
    * @return {module:model/PaymentCustomer} The populated <code>PaymentCustomer</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PaymentCustomer();
                        
            
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
            }
            if (data.hasOwnProperty('address')) {
                obj['address'] = ApiClient.convertToType(data['address'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('metadata')) {
                obj['metadata'] = ApiClient.convertToType(data['metadata'], Object);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('shippingAddress')) {
                obj['shippingAddress'] = ApiClient.convertToType(data['shippingAddress'], 'String');
            }
            if (data.hasOwnProperty('subscriptions')) {
                obj['subscriptions'] = ApiClient.convertToType(data['subscriptions'], [PaymentCustomerSubscription]);
            }
        }
        return obj;
    }

    /**
    * @member {String} customerId
    */
    'customerId' = undefined;
    /**
    * @member {String} address
    */
    'address' = undefined;
    /**
    * @member {String} description
    */
    'description' = undefined;
    /**
    * @member {String} email
    */
    'email' = undefined;
    /**
    * @member {Object} metadata
    */
    'metadata' = undefined;
    /**
    * @member {String} name
    */
    'name' = undefined;
    /**
    * @member {String} phone
    */
    'phone' = undefined;
    /**
    * @member {String} shippingAddress
    */
    'shippingAddress' = undefined;
    /**
    * @member {Array.<module:model/PaymentCustomerSubscription>} subscriptions
    */
    'subscriptions' = undefined;




}
