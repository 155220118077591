import {
    DataProvider, GetManyReferenceResult,
    GetListParams, GetListResult,
    GetOneParams, GetOneResult,
    UpdateParams, UpdateResult,
    CreateParams, CreateResult,
    DeleteResult, GetManyResult, 
} from "ra-core"
import { container } from "tsyringe";
import { UserProfilesApi, UserProfile, UserProfileOrganization } from "../../apiClient";
import { GetListParamsWithParent, GetOneParamsWithParent, CreateParamsWithParent, UpdateParamsWithParent, DeleteParamsWithParent, GetManyParamsWithParent, GetManyReferenceParamsWithParent } from "./common";

const _UserProfileApi = () => container.resolve<UserProfilesApi>("UserProfilesApi");

const placeholder = (resource: any, params: any) => {
    return Promise.reject({ resource, params });
}

export const userProfileDataProvider: DataProvider = {
    getList: placeholder,
    updateMany: placeholder,
    delete: placeholder,
    deleteMany: placeholder,
    getMany: placeholder,
    getManyReference: placeholder,
    /**
     * Get UserProfile
     */
    getOne: (resource: string, params: GetOneParams): Promise<GetOneResult> => {
        return new Promise((resolve, reject) => {
            _UserProfileApi()
                .userProfilesRetrieveUserProfile(params.id, (error: Error, userProfile: UserProfile) => {
                    if (error) return reject(error);
                    return resolve({
                        data: userProfile
                    } as GetOneResult)
                })
        });
    },
    /**
     * Create UserProfile
     */
    create: (resource: string, params: CreateParams): Promise<CreateResult> => {
        return new Promise((resolve, reject) => {
            _UserProfileApi()
                .userProfilesCreateUserProfile(params.data, (error: Error, data: UserProfile) => {
                    if (error) return reject(error);
                    return resolve({ data })
                });
        })
    },
    /**
     * Update UserProfile
     */
    update: (resource: string, params: UpdateParams): Promise<UpdateResult> => {
        return new Promise((resolve, reject) => {
            _UserProfileApi()
                .userProfilesUpdateUserProfile(params.data, params.id, (error: Error, data: UserProfile) => {
                    if (error) return reject(error);
                    return resolve({
                        data
                    });
                });
        });
    },
}

export const userProfileOrganizationDataProvider: DataProvider = {
    getMany: (resource: string, params: GetManyParamsWithParent): Promise<GetManyResult> => {
        console.log("getMany", resource, params);
        return Promise.reject("Not implemented");
    },
    getManyReference: (resource: string, params: GetManyReferenceParamsWithParent): Promise<GetManyReferenceResult> => {
        console.log("getMany", resource, params);
        return Promise.reject("Not implemented");
    },
    updateMany: placeholder,
    deleteMany: placeholder,
    /**
     * Get list of Organizations that the user belongs to.
     */
    getList: (resource: string, params: GetListParamsWithParent): Promise<GetListResult> => {
        console.log("userProfileOrganizationDataProvider, getList", params, resource);
        return new Promise((resolve, reject) => {
            _UserProfileApi()
                .userProfilesRetrieveUserProfileOrganizations(params.filter.parentId, (error: Error, results: UserProfileOrganization[]) => {
                    if (error) return reject(error);
                    else return resolve({
                        data: results.map(r => ({ ...r, id: r.organizationId })),
                        total: results.length
                    });
                });
        });
    },
    /**
     * Get an Organization that the user belongs to; by organizationId
     */
    getOne: (resource: string, params: GetOneParamsWithParent): Promise<GetOneResult> => {
        return new Promise((resolve, reject) => {
            _UserProfileApi()
                .userProfilesRetrieveUserProfileOrganizationByOrganizationId(
                    params.parentId,
                    params.id,
                    (error: Error, result: UserProfileOrganization) => {
                        if (error) return reject(error);
                        else resolve({
                            data: { ...result, id: result.organizationId }
                        });
                    }
                );
        });
    },
    /**
     * Allow user to join Organization
     */
    create: (resource: string, params: CreateParamsWithParent): Promise<CreateResult> => {
        return new Promise((resolve, reject) => {
            _UserProfileApi().userProfilesJoinOrganization(
                params.parentId,
                (params.data as UserProfileOrganization).organizationId,
                (error: Error, result: UserProfileOrganization) => {
                    if (error) return reject(error);
                    else return resolve({
                        data: { ...result, id: result.organizationId }
                    });
                });
        });
    },
    /**
     * Update UserProfileOrganization - typically used to update permissions
     */
    update: (resource: string, params: UpdateParamsWithParent): Promise<UpdateResult> => {
        return new Promise((resolve, reject) => {
            _UserProfileApi()
                .userProfilesUpdateUserProfileOrganization(
                    params.data,
                    params.parentId,
                    params.id,
                    (error: Error, result: UserProfileOrganization) => {
                        if (error) return reject(error);
                        return resolve({
                            data: { ...result, id: result.organizationId },
                        });
                    }
                );
        });
    },
    /**
     * Leave Organization
     */
    delete: (resource: string, params: DeleteParamsWithParent): Promise<DeleteResult> => {
        return new Promise((resolve, reject) => {
            _UserProfileApi()
                .userProfilesLeaveOrganization(
                    params.parentId,
                    params.id,
                    (error: Error, result: any) => {
                        if (error) return reject(error);
                        else resolve({})
                    }
                )
        });
    },
}