/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PaymentCustomerSubscriptionPrice from './PaymentCustomerSubscriptionPrice';

/**
* The PaymentProduct model module.
* @module model/PaymentProduct
* @version 0.0.1
*/
export default class PaymentProduct {
    /**
    * Constructs a new <code>PaymentProduct</code>.
    * @alias module:model/PaymentProduct
    * @class
    * @param productId {String} 
    * @param active {Boolean} 
    * @param description {String} 
    * @param name {String} 
    * @param prices {Array.<module:model/PaymentCustomerSubscriptionPrice>} 
    */

    constructor(productId, active, description, name, prices) {
        
        
        this['productId'] = productId;
        this['active'] = active;
        this['description'] = description;
        this['name'] = name;
        this['prices'] = prices;
        
    }

    /**
    * Constructs a <code>PaymentProduct</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PaymentProduct} obj Optional instance to populate.
    * @return {module:model/PaymentProduct} The populated <code>PaymentProduct</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PaymentProduct();
                        
            
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'String');
            }
            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('prices')) {
                obj['prices'] = ApiClient.convertToType(data['prices'], [PaymentCustomerSubscriptionPrice]);
            }
        }
        return obj;
    }

    /**
    * @member {String} productId
    */
    'productId' = undefined;
    /**
    * @member {Boolean} active
    */
    'active' = undefined;
    /**
    * @member {String} description
    */
    'description' = undefined;
    /**
    * @member {String} name
    */
    'name' = undefined;
    /**
    * @member {Array.<module:model/PaymentCustomerSubscriptionPrice>} prices
    */
    'prices' = undefined;




}
