import { AuthProvider } from "ra-core";
import { auth } from "firebase";
import { container } from "tsyringe";
import { ApiClient } from "../apiClient";

const placeholder = (method: string) => (resource?: any, params?: any) => {
    console.log("placeholder", method, resource, params);
    if (method == "getPermissions") {
        return authProvider.checkAuth({});
    }
    return Promise.resolve();
}

let refreshInterval: any;
const REFRESH_INTERVAL = 1000 * 60 * 45; // 45 minutes

function startRefreshTokenInterval() {
    clearRefreshTokenInterval();
    console.log("startRefreshTokenInterval");
    refreshInterval = setInterval(() => {
        console.log("Refreshing Token");
        if (auth().currentUser) {
            auth().currentUser!.getIdToken(true)
                .then(value =>{
                    attachCredentialsToApiClient(value);
                })
                .catch(console.error);
        }
    }, REFRESH_INTERVAL);
}

function clearRefreshTokenInterval() {
    console.log("clearRefreshTokenInterval");
    if (refreshInterval)
        clearInterval(refreshInterval);
}

function attachCredentialsToApiClient(value: any) {
    const apiClient = container.resolve<ApiClient>("ApiClient");
    if (value) {
        (apiClient.defaultHeaders as any).authorization = value._lat;
    }
}

const authProvider: AuthProvider = {
    login: (params: any) => {
        console.log("login", params);
        return Promise.resolve();
    },
    logout: (params) => {
        console.log("logout", params);
        clearRefreshTokenInterval();
        return auth().signOut();
    },
    checkAuth: (params) => {
        console.log("checkAuth", params);
        return new Promise((resolve, reject) => {
            auth().onAuthStateChanged(
                (value: any) => {
                    startRefreshTokenInterval();
                    attachCredentialsToApiClient(value);
                    if (value) {
                        resolve(value);
                    }
                    else {
                        console.error("Rejecting checkAuth");
                        reject();
                    }
                },
                (error: any) => {
                    console.error(error);
                    reject(error);
                },
                () => { console.info("completed") }
            );
        })
    },
    checkError: placeholder("checkError"),
    getPermissions: placeholder("getPermissions"),
}

export default authProvider;