/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The OrganizationUserProfile model module.
* @module model/OrganizationUserProfile
* @version 0.0.1
*/
export default class OrganizationUserProfile {
    /**
    * Constructs a new <code>OrganizationUserProfile</code>.
    * @alias module:model/OrganizationUserProfile
    * @class
    * @param userProfileId {String} 
    * @param role {module:model/OrganizationUserProfile.RoleEnum} 
    */

    constructor(userProfileId, role) {
        
        
        this['userProfileId'] = userProfileId;
        this['role'] = role;
        
    }

    /**
    * Constructs a <code>OrganizationUserProfile</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/OrganizationUserProfile} obj Optional instance to populate.
    * @return {module:model/OrganizationUserProfile} The populated <code>OrganizationUserProfile</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrganizationUserProfile();
                        
            
            if (data.hasOwnProperty('userProfileId')) {
                obj['userProfileId'] = ApiClient.convertToType(data['userProfileId'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} userProfileId
    */
    'userProfileId' = undefined;
    /**
    * @member {module:model/OrganizationUserProfile.RoleEnum} role
    */
    'role' = undefined;



    /**
    * Allowed values for the <code>role</code> property.
    * @enum {String}
    * @readonly
    */
    static RoleEnum = {
        /**
         * value: "ADMIN"
         * @const
         */
        "ADMIN": "ADMIN",
        /**
         * value: "USER"
         * @const
         */
        "USER": "USER"    };

}
