/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The PaymentCustomerSubscriptionPrice model module.
* @module model/PaymentCustomerSubscriptionPrice
* @version 0.0.1
*/
export default class PaymentCustomerSubscriptionPrice {
    /**
    * Constructs a new <code>PaymentCustomerSubscriptionPrice</code>.
    * @alias module:model/PaymentCustomerSubscriptionPrice
    * @class
    * @param priceId {String} 
    * @param active {Boolean} 
    * @param nickname {String} 
    * @param productId {String} 
    */

    constructor(priceId, active, nickname, productId) {
        
        
        this['priceId'] = priceId;
        this['active'] = active;
        this['nickname'] = nickname;
        this['productId'] = productId;
        
    }

    /**
    * Constructs a <code>PaymentCustomerSubscriptionPrice</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PaymentCustomerSubscriptionPrice} obj Optional instance to populate.
    * @return {module:model/PaymentCustomerSubscriptionPrice} The populated <code>PaymentCustomerSubscriptionPrice</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PaymentCustomerSubscriptionPrice();
                        
            
            if (data.hasOwnProperty('priceId')) {
                obj['priceId'] = ApiClient.convertToType(data['priceId'], 'String');
            }
            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('nickname')) {
                obj['nickname'] = ApiClient.convertToType(data['nickname'], 'String');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'String');
            }
            if (data.hasOwnProperty('currency')) {
                obj['currency'] = ApiClient.convertToType(data['currency'], 'String');
            }
            if (data.hasOwnProperty('unitAmount')) {
                obj['unitAmount'] = ApiClient.convertToType(data['unitAmount'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} priceId
    */
    'priceId' = undefined;
    /**
    * @member {Boolean} active
    */
    'active' = undefined;
    /**
    * @member {String} nickname
    */
    'nickname' = undefined;
    /**
    * @member {String} productId
    */
    'productId' = undefined;
    /**
    * @member {String} currency
    */
    'currency' = undefined;
    /**
    * Amount in cents to be charged
    * @member {Number} unitAmount
    */
    'unitAmount' = undefined;




}
