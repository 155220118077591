import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { ShowControllerProps } from "ra-core";

export default function EditUserProfile(props: ShowControllerProps) {
    return (
        <Edit {...props}>
            <SimpleForm redirect="show">
                <TextInput source="firstName" />
                <TextInput source="lastName" />
            </SimpleForm>
        </Edit>
    );
}