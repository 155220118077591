import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import './App.css';
import { Admin, Resource, ShowGuesser, EditGuesser } from "react-admin";
import _dataProviders from './core/services/dataProviders';
import authProvider from './core/services/authProvider';
import { LoginPage } from './Pages/LoginPage';
import ShowUserProfile from './Components/UserProfile/ShowUserProfile';
import { createMuiTheme } from '@material-ui/core';
import Layout from './Components/Layout';
import EditUserProfile from './Components/UserProfile/EditUserProfile';
import ListProducts from './Components/Subscriptions/ListProducts';
import HomePage from './Pages/HomePage';
import ShowOrganization from './Components/Organizations/ShowOrganization';
import ListOrganizations from './Components/Organizations/ListOrganizations';
import CreateOrganization from './Components/Organizations/CreateOrganization';
import EditOrganization from './Components/Organizations/EditOrganization';
import i18nProvider from './core/i18n/i18nProvider';
import { auth } from 'firebase';

const history = createBrowserHistory();

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});


const App: React.FC = () => {
    const routes = [
        <Route exact path="/" component={HomePage} />
    ]

    function renderApp() {
        return (
            <Admin
                history={history}
                loginPage={LoginPage}
                customRoutes={routes}
                dataProvider={_dataProviders}
                layout={Layout}
                authProvider={authProvider}
                // menu={Empty}
                i18nProvider={i18nProvider}
                theme={theme}
            >
                {fetchResources}
            </Admin>
        )
    }

    return (
        <Router>
            {renderApp()}
        </Router>
    );
}

const allResources = [
    <Resource name="products" list={ListProducts}></Resource>,
    <Resource name="userprofiles" show={ShowUserProfile} edit={EditUserProfile}></Resource>,
    <Resource name="organizations"
        show={ShowOrganization}
        edit={EditOrganization}
        // list={ListOrganizations}
        create={CreateOrganization} />,
    <Resource name="organization/userprofiles" />,
];

const fetchResources = async (permissions: any) => {
    console.log("Permissions", permissions);
    return Promise.resolve(allResources);
    // return new Promise((resolve, reject) => {
    //     return authProvider.checkAuth({})
    //         .then(authValue => {
    //             allResources.forEach(resource => {
    //                 console.log(resource.props.options, authValue);
    //                 if (resource.props.options && resource.props.options.parentId == USER_PROFILE_ID) {
    //                     resource.props.options.parentId = (authValue as any).uid
    //                 }
    //             });
    //             return allResources;
    //         })
    //         .catch(error => resolve(allResources));
    // });
}


export default App;

