/**
 * realtime-fleet-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The PaymentSessionLineItem model module.
* @module model/PaymentSessionLineItem
* @version 0.0.1
*/
export default class PaymentSessionLineItem {
    /**
    * Constructs a new <code>PaymentSessionLineItem</code>.
    * @alias module:model/PaymentSessionLineItem
    * @class
    * @param price {String} 
    * @param quantity {Number} 
    */

    constructor(price, quantity) {
        
        
        this['price'] = price;
        this['quantity'] = quantity;
        
    }

    /**
    * Constructs a <code>PaymentSessionLineItem</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PaymentSessionLineItem} obj Optional instance to populate.
    * @return {module:model/PaymentSessionLineItem} The populated <code>PaymentSessionLineItem</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PaymentSessionLineItem();
                        
            
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'String');
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} price
    */
    'price' = undefined;
    /**
    * @member {Number} quantity
    */
    'quantity' = undefined;




}
