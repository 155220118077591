import React, { useState, useEffect } from "react";
import { UserMenu as AdminUserMenu, MenuItemLink, AppBar as AdminAppBar } from "react-admin";
import SettingsIcon from '@material-ui/icons/Settings';

import { } from "ra-core";
import { auth } from "firebase";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

function UserMenu(props: any) {
    const [profile, setProfile] = useState<firebase.User>();
    useEffect(() => {
        setProfile(auth().currentUser as any);
        auth().onAuthStateChanged(state => {
            setProfile(auth().currentUser as any);
        });
    }, []);
    return (
        <AdminUserMenu label={profile ? profile.displayName : ''} {...props}>
            {profile && <MenuItemLink
                to={`/userprofiles/${profile.uid}/show`}
                primaryText="*Configuration*"
                leftIcon={<SettingsIcon />}
            />}
        </AdminUserMenu>
    )
}

export default function AppBar(props: any) {
    return (
        <AdminAppBar {...props} title={"WHAT"} userMenu={<UserMenu />}>
            <Link to="/">HOME</Link>
            <span style={{ flex: 1 }} />
        </AdminAppBar>
    )
}