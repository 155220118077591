import React, { useState, useEffect } from "react";
import { Loading, Error, useTranslate } from "react-admin";
import authProvider from "../core/services/authProvider";
import { UserProfile, PaymentCustomer, UserProfileOrganization } from "../core/apiClient";
import { userProfileDataProvider } from "../core/services/dataProviders/userProfiles";
import { Grid, Card, CardContent, Typography, CardActions, Button, LinearProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { customerDataProvider } from "../core/services/dataProviders/payments";
import { Empty } from "../Components/Layout";
import LinkButton from "../Components/Common/LinkButton";

export default function HomePage() {
    const [uid, setUid] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [userProfile, setUserProfile] = useState<UserProfile>();
    const [customer, setCustomer] = useState<PaymentCustomer>();
    const [loadingCustomer, setLoadingCustomer] = useState(true);
    const t = useTranslate();

    useEffect(() => {
        authProvider.checkAuth({}).then(value => {
            setUid((value as any).uid);
        }).catch(error => setError(error));
    }, []);

    useEffect(() => {
        if (!uid) {
            return;
        }
        userProfileDataProvider.getOne("userprofiles", { id: uid! })
            .then(result => {
                setUserProfile(result.data as UserProfile);
                setLoading(false);
            })
            .catch(setError);
        customerDataProvider.getOne("customers", { id: uid! })
            .then(result => {
                setCustomer(result.data as unknown as PaymentCustomer);
                setLoadingCustomer(false);
            })
            .catch(setError);
    }, [uid]);

    if (error) {
        return <Error error={error} errorInfo={{ componentStack: "HomePage" }} />
    }
    if (loading) {
        return <Loading />
    }

    return (
        <>
            <Typography variant="h5">
                {t("app.home.messages.welcome")}, {userProfile?.firstName}!
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography>{t("api")}</Typography>
                            <Typography color="textSecondary" variant="body2">
                                {t("Interested in our API?")}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button target="_blank" href={`${process.env.REACT_APP_CLOUD_FUNCTION_ENDPOINT}/api/docs/`}>
                                {t("app.home.messages.get_started")}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography>{t("Blog")}</Typography>
                            <Typography color="textSecondary" variant="body2">
                                {t("Check out our blog for the latest news related to Fleet on Route.")}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button>{t("app.home.messages.get_started")}</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <OrganizationCard userProfile={userProfile} />
                </Grid>
                <Grid item xs={12}>
                    {loadingCustomer && <LinearProgress />}
                    {!loadingCustomer && <Card>
                        <CardContent>
                            <Typography>{t("Subscription")}</Typography>
                            <Typography color="textSecondary" variant="body2">
                                {t("Upgrade your account or manage your subscription.")}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button>
                                <Link
                                    to={customer ? `/customers/${userProfile!.id}/show` : "/subscriptions/create"}
                                >
                                    {customer && t("Manage Subscription")}
                                    {!customer && t("app.home.messages.get_started")}
                                </Link>
                            </Button>
                        </CardActions>
                    </Card>}
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography>{t("Manage Organization")}</Typography>
                        </CardContent>
                        <CardActions>
                            <Button><Link to="/manage-organization">{t("app.home.messages.get_started")}</Link></Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography>{t("Other Links")}</Typography>
                            <Button>{t("Support Center")}</Button>
                            <Button>{t("Preferences")}</Button>
                            <Button>{t("Guides")}</Button>
                            <Button>{t("Other")}</Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

type OrganizationCardProps = {
    userProfile?: UserProfile
}

function OrganizationCard(props: OrganizationCardProps) {
    const t = useTranslate();
    if (!props.userProfile) {
        return <Empty />;
    }

    const organization = (props.userProfile.organizations as UserProfileOrganization[])
        .find(org => org.role == "ADMIN");

    return (
        <Card>
            <CardContent>
                <Typography>{t("app.home.messages.manage_your_organization")}</Typography>
            </CardContent>
            <CardActions>
                {!organization &&
                    <LinkButton to={{
                        pathname: "/organizations/create",
                        state: { record: { ownerUserProfileId: props.userProfile.id } },
                    }}>{t("app.home.messages.create_organization")}</LinkButton>
                }
                {
                    organization &&
                    <LinkButton to={`/organizations/${organization.organizationId}/show`}>
                        {t("app.home.messages.manage_organization")}
                    </LinkButton>
                }
            </CardActions>
        </Card>
    )
}