import { DataProvider, GetListParams, GetListResult, GetOneParams, GetOneResult } from "ra-core"
import { auth } from "firebase";
import { Failure, BadRequestFailure } from "../../models";
import { container } from "tsyringe";
import { UserProfilesApi, UserProfile } from "../../apiClient";
import { customerDataProvider, productDataProvider, subscriptionDataProvider } from "./payments";
import { organizationDataProvider, organizationUserProfileDataProvider } from "./organizations";
import { userProfileDataProvider, userProfileOrganizationDataProvider } from "./userProfiles";


const _dataProviders: { [key: string]: DataProvider } = {
    "customers": customerDataProvider,
    "products": productDataProvider,
    "organizations": organizationDataProvider,
    "userprofiles": userProfileDataProvider,
    "subscriptions": subscriptionDataProvider,
    "customer/subscriptions": subscriptionDataProvider,
    "organization/userprofiles": organizationUserProfileDataProvider,
    "userprofile/organizations": userProfileOrganizationDataProvider,
}

const dataProviders: DataProvider = {
    getList: (resource: any, params: any) => {
        console.log("DataProvider.getList", resource, params);
        return _dataProviders[resource].getList(resource, params);
    },
    getOne: (resource: any, params: any) => _dataProviders[resource].getOne(resource, params),
    getMany: (resource: any, params: any) => _dataProviders[resource].getMany(resource, params),
    getManyReference: (resource: any, params: any) => _dataProviders[resource].getManyReference(resource, params),
    create: (resource: any, params: any) => _dataProviders[resource].create(resource, params),
    update: (resource: any, params: any) => _dataProviders[resource].update(resource, params),
    updateMany: (resource: any, params: any) => _dataProviders[resource].updateMany(resource, params),
    delete: (resource: any, params: any) => _dataProviders[resource].delete(resource, params),
    deleteMany: (resource: any, params: any) => _dataProviders[resource].deleteMany(resource, params),
}

export default dataProviders;