import React, { useEffect, useState } from "react";
import { } from "react-admin";
import { GetOneParamsWithParent } from "../../core/services/dataProviders/common";
import { InputLabel, LinearProgress } from "@material-ui/core";
import _dataProviders from "../../core/services/dataProviders";

type ChildTextFieldProps = {
    parent: {
        source: string;
        /**
         * Parent record's identify field name
         */
        id: string;
    }
    label: string;
    source: string;
    record?: any;
    child: {
        resource: string;
        source: string;
    }
}


export default function ChildTextField(props: ChildTextFieldProps) {
    const { parent, child, record, label } = props;
    const [field, setField] = useState();

    useEffect(() => {
        if (props.record) {
            _dataProviders
                .getOne(child.resource, {
                    id: record[parent.source],
                    parentId: record[parent.id]
                } as GetOneParamsWithParent)
                .then((childRecord: any) => {
                    setField(childRecord.data[child.source]);
                });
        }
    }, [props.record]);

    return <>
        <InputLabel>{label}</InputLabel>
        {!field && <LinearProgress />}
        {field && <span>{field}</span>}
    </>;
}